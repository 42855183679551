html {
  font-size: 16px;
}

main {
  min-height: 80vh;
  /* padding-top: 220px; */
  /* margin-top: 3mm; */
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

/* carousel */

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}

.carousel-caption {
  position: relative;
  top: 0;
  left: 0;
  padding-bottom: 0;
}

.carousel-caption h2 {
  font-size: 2rem;
  color: #fff;
}

.carousel-caption p {
  font-size: 1rem;
  color: #fff;
}

.carousel img {
  margin-top: 0;
  padding-top: 0;
  height: 390px;
  width: 100%;

  /* padding-top: 10px; */
  /* margin-top: 10px; */
  /* border-radius: 50%; */
  /* margin-left: 5%; */
  /* margin-right: auto; */
  /* margin-bottom: 50px; */
}

.carousel a {
  margin: 0 auto;
  height: 600px;
  width: auto;
}

.carouselCSS {
  height: 550px;
}

.sidebar {
  background: rgb(56, 56, 56);
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.sidebarLink {
  color: #fff
}

.sidebarLink:hover {
  color: #fff
}

#main-footer {
  background-color: rgb(56, 56, 56);
  color: #fff;
  text-align: center;
  padding: 20px;
  margin-top: 40px;
}

@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}
